exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-kontakt-tsx": () => import("./../../../src/pages/kontakt.tsx" /* webpackChunkName: "component---src-pages-kontakt-tsx" */),
  "component---src-pages-nas-tym-tsx": () => import("./../../../src/pages/nas-tym.tsx" /* webpackChunkName: "component---src-pages-nas-tym-tsx" */),
  "component---src-pages-o-nas-tsx": () => import("./../../../src/pages/o-nas.tsx" /* webpackChunkName: "component---src-pages-o-nas-tsx" */),
  "component---src-pages-pro-lektory-tsx": () => import("./../../../src/pages/pro-lektory.tsx" /* webpackChunkName: "component---src-pages-pro-lektory-tsx" */),
  "component---src-pages-pro-ridice-tsx": () => import("./../../../src/pages/pro-ridice.tsx" /* webpackChunkName: "component---src-pages-pro-ridice-tsx" */)
}

